/* eslint-disable react/sort-comp */
import { css } from 'astroturf';
import { AnimatePresence, motion } from 'framer-motion';
import sortBy from 'lodash/sortBy';
import React from 'react';
import { Download, Upload } from 'react-bytesize-icons';

import CTA from '../components/CTA';
import PageHeader from '../components/PageHeader';
import SEO from '../components/SEO';
import Layout from '../layout';
import Link from '../components/link';

const CLIENT_ID = '369895063682-4r9hmmhg330bb66mjvutm3k716ki3lu4.apps.googleusercontent.com';
const styles = css`
  .intro {
    margin: 5rem auto;
    max-width: 40rem;

    & h2,
    & p {
      text-align: center;
      margin-bottom: 2rem;
    }
    & ol {
      margin: 0 auto 2rem;
      width: max-content;
    }
  }

  .form {
    margin: 0 auto;
    max-width: 60rem;
    padding: 0 1rem;
    position: relative;
    @media screen and (min-width: 40em) {
      padding: 0 2rem;
    }

    & p {
      margin-top: 1rem;
      text-align: center;
      font-size: 0.9em;
    }
  }
  .button {
    position: relative;
    margin-top: -2rem;
    display: grid;
    place-items: center;
  }
  .button > * {
    margin: auto;
  }

  .signin,
  .signout {
    border-radius: 4px;
    box-sizing: border-box;
    transition: background-color 0.218s, border-color 0.218s;
    -moz-user-select: none;
    background-color: #fff;
    background-image: none;
    border: 1px solid #dadce0;
    color: #3c4043;
    cursor: pointer;
    font-family: 'Google Sans', arial, sans-serif;
    font-size: 14px;
    height: 40px;
    letter-spacing: 0.25px;
    outline: none;
    overflow: hidden;
    padding: 4px 12px 4px 3px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    background-color: #1a73e8;
    border: none;
    font-weight: bold;
    color: #fff;
    display: inline-flex;
    margin: 0 auto;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-start;

    &:hover {
      background: #5194ee;
    }

    & span {
      display: inline-grid;
      place-items: center;
      width: 34px;
      height: 34px;
      background: #fff;
      border-radius: 3px 0 0 3px;

      & svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  .selectwrap {
    margin-top: 3rem;
    margin-bottom: 3rem;

    & label {
      display: block;
      font-weight: bold;
      text-align: center;

      & em {
        font-weight: normal;
        white-space: nowrap;
      }
    }

    & small {
      display: block;
      margin-bottom: 0.5rem;
      font-weight: bold;
      text-align: center;
    }

    & select {
      background: #fff;
      position: relative;
      display: block;
      font-size: 1.25rem;
      padding: 1rem;
      margin: 0 auto;
      width: 100%;
      max-width: 60rem;
      height: 56px; /* this makes the height work for mac select */
      border-radius: 5px;
      border: 0;
      box-shadow: rgba(0, 0, 0, 0.025) 0 0 0 1px, rgba(0, 0, 0, 0.05) 0px 1px 0px, rgba(0, 0, 0, 0.03) 0px 0px 8px,
        rgba(0, 0, 0, 0.1) 0px 20px 30px;
    }
  }

  .paneBlock {
    max-width: 56rem;
    position: relative;
    width: auto;
    margin: 0rem 1rem 5rem;
    list-style: none;
    background: #fff;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.025) 0 0 0 1px, rgba(0, 0, 0, 0.05) 0px 1px 0px, rgba(0, 0, 0, 0.03) 0px 0px 8px,
      rgba(0, 0, 0, 0.1) 0px 20px 30px;

    @media screen and (min-width: 56em) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .loading {
    text-align: center;
    padding: 6rem 2rem;
  }
  .actionBar {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-content: flex-start;
    overflow: hidden;
    background: #ebebee;
    line-height: 60px;

    &:first-child {
      border-radius: 5px 5px 0 0;
    }
    &:last-child {
      border-radius: 0 0 5px 5px;
    }

    @media screen and (min-width: 40rem) {
      padding-top: 0;
      flex-direction: row;
      align-content: stretch;

      & .sliderFieldset input#numberofpanes {
        width: 180px;
      }
    }
  }

  .actionBar {
    & fieldset {
      margin: 0;
      padding: 0;
      border: 0;
      line-height: 60px;
      height: 60px;
      white-space: nowrap;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0;

      &.sortFieldset div {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        vertical-align: middle;

        & label {
          margin-left: 1.5rem;
          width: auto;
          flex: 0 1 auto;
          display: block;
          font-weight: normal;
          vertical-align: middle;
          & input {
            vertical-align: middle;
          }
        }
        & legend {
          flex: 0 1 auto;
          display: block;
          font-weight: bold;
          vertical-align: middle;
        }
      }
    }

    & label {
      display: inline-block;
      font-weight: bold;
      font-feature-settings: 'tnum';
    }

    & .sliderFieldset label {
      width: 70px;
    }
    & .sliderFieldset input {
      height: 30px;
      outline: none;
      -webkit-appearance: none;
      appearance: none;
      background: transparent;
      opacity: 1;
      transition: 0.25s ease-in-out;
      outline: none !important;
      font-size: 1.25rem;
      padding: 0rem;
      vertical-align: middle;
      margin: 0 1rem;
    }

    & input::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      height: 20px;
      width: 20px;
      border-radius: 20px;
      background: #0079b1;
      border: 2px solid #ebebee;
      margin-top: -8px;
      transition: 0.25s ease-in-out background;
    }

    & input::-moz-range-thumb {
      -webkit-appearance: none;
      appearance: none;
      height: 20px;
      width: 20px;
      border-radius: 20px;
      background: #0079b1;
      border: 2px solid #ebebee;
      margin-top: -8px;
      transition: 0.25s ease-in-out background;
    }
    & input::-webkit-slider-runnable-track {
      width: 100%;
      height: 4px;
      background: #0a1530;
      border-radius: 2px;
    }
    & input::-moz-range-track {
      width: 100%;
      height: 4px;
      background: #0a1530;
      border-radius: 2px;
    }

    & input:hover::-webkit-slider-thumb {
      background: #0a1530;
    }
    & input:hover::-moz-range-thumb {
      background: #0a1530;
    }
  }

  .open {
    display: block;
    line-height: 36px;
    flex: 1 1 100%;
    text-align: center;
    border: 0;
    color: #fff;
    background: #0a1530;
    padding: 0 1rem;
    height: 60px;
    line-height: 60px;
    font-size: 1.2rem;
    transition: 0.25s ease-out all;
    margin: 0;

    & i {
      font-style: normal;
      display: none;
      @media screen and (min-width: 40rem) {
        display: inline;
      }
    }

    &:hocus {
      background: #67b26f;
      &:after {
        display: none;
      }
    }

    & svg {
      margin-left: 0.5rem;
      opacity: 0.8;
      vertical-align: middle;
    }
  }

  .download {
    display: block;
    line-height: 36px;
    flex: 1 1 100%;
    text-align: center;
    border: 0;
    color: #fff;
    background: #107db5;
    padding: 0 1rem;
    height: 60px;
    line-height: 60px;
    font-size: 1rem;
    transition: 0.25s ease-out all;
    margin: 0;

    & i {
      font-style: normal;
      display: none;
      @media screen and (min-width: 40rem) {
        display: inline;
      }
    }

    &:hocus {
      background: #67b26f;
      &:after {
        display: none;
      }
    }

    & svg {
      margin-left: 0.5rem;
      opacity: 0.8;
      vertical-align: middle;
    }
  }

  .panelist {
    position: relative;
    width: auto;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;

    & li {
      margin-right: 8px;
      margin-bottom: 1rem;
      text-align: center;
      font-size: 10px;
      line-height: 2;
    }

    & div {
      margin-bottom: 4px;
      background: #ebebee;
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.1) 0 1px 0 1px, rgba(0, 0, 0, 0.05) 0px 1px 0px, rgba(0, 0, 0, 0.02) 0px 0px 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;

      & i {
        display: block;
        opacity: 0.8;
        font-style: normal;
      }
    }
  }

  .sizeOverview {
    overflow: hidden;
    position: relative;
    width: 100%;
    border-bottom: 1px solid #0079b1;

    & .dimensions {
      position: absolute;
      left: 50%;
      top: 0;
      transform-origin: top center;
      transform: translateX(-50%) scale(0.8);
      border: 2px solid #0079b1;
      border-top: 0;
      border-radius: 0 0 2px 2px;
      color: transparent;
      padding: 2rem;
      background: transparent;

      & i {
        font-size: 0.9em;
        opacity: 0.8;
      }

      &.mine {
        border-style: dotted;
        border-width: 4px;
        border-top-width: 0;
      }

      &.xsmall {
        border-color: #7900b1;
      }
      &.small {
        border-color: #0079b1;
      }
      &.medium {
        border-color: #00b179;
      }
      &.large {
        border-color: #b10079;
      }
      &.xlarge {
        border-color: #b17900;
      }

      &:hover {
        opacity: 1 !important;
        color: #000;

        &.xsmall {
          background: #7900b188;
        }
        &.small {
          background: #0079b188;
        }
        &.medium {
          background: #00b17988;
        }
        &.large {
          background: #b1007988;
        }
        &.xlarge {
          background: #b1790088;
        }
      }
    }
  }

  .legend {
    display: flex;
    margin: 1rem 0 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    list-style: none;
    flex-wrap: wrap;

    @media screen and (min-width: 56em) {
      flex-wrap: nowrap;
    }

    & li {
      margin: 0 0.5rem;
    }
    & li div {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 12px;
      margin-right: 0.25rem;
    }
    & .xsmall {
      background: #7900b1;
    }
    & .small {
      background: #0079b1;
    }
    & .medium {
      background: #00b179;
    }
    & .large {
      background: #b10079;
    }
    & .xlarge {
      background: #b17900;
    }
  }

  canvas {
    display: block;
    position: relative;
    width: 100%;
    height: 20px;
    image-rendering: crisp-edges;
  }

  .canvasTitle {
    text-align: center;
    color: #0079b1;
    margin: 0 auto 2rem;
    max-width: 56rem;
  }

  .badge {
    vertical-align: middle;
    font-size: 0.8em;
    line-height: 1;
    background: #107db5;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    margin-left: 0.5rem;
    padding: 3px 5px 1px;

    &::before {
      content: '';
      display: inline-block;
      height: 10px;
      vertical-align: middle;
    }
  }
`;

const CATEGORIES = {
  xsmall: 414,
  small: 600,
  medium: 1024,
  large: 1441,
  xlarge: 99999,
};

const getCategoryClass = (index) => {
  if (index === 0) return styles.xsmall;
  if (index === 1) return styles.small;
  if (index === 2) return styles.medium;
  if (index === 3) return styles.large;
  if (index === 4) return styles.xlarge;
};

const getCategoryName = (index) => {
  if (index === 0) return 'Extra small (<414px)';
  if (index === 1) return 'Small (<600px)';
  if (index === 2) return 'Medium (<1024px)';
  if (index === 3) return 'Large (<1441px)';
  if (index === 4) return 'Extra large (1441px+)';
};
const zoomFactor = 0.8;
const cutOffPercentage = 0.05;
const drawOnCanvas = (canvas) => {
  canvas.setAttribute('touch-action', 'none');

  const windowWidth = window.innerWidth;
  canvas.width = windowWidth;
  canvas.height = 20;

  const ctx = canvas.getContext('2d');
  let offset = 0;
  ctx.imageSmoothingEnabled = false;
  ctx.font = '9px "Inter var"';
  ctx.strokeStyle = '#0079b1';
  ctx.fillStyle = '#0079b1';

  ctx.clearRect(0, 0, 0, windowWidth, 20);

  do {
    const length = (offset * 2) % 100 === 0 ? 12 : (offset * 2) % 50 === 0 ? 14 : 16;

    ctx.strokeStyle = (offset * 2) % 50 === 0 ? '#0079b1' : '#0079b188';

    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.moveTo(parseInt(windowWidth / 2 + zoomFactor * offset, 10) + 0.5, length);
    ctx.lineTo(parseInt(windowWidth / 2 + zoomFactor * offset, 10) + 0.5, 20);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(parseInt(windowWidth / 2 - zoomFactor * offset, 10) + 0.5, length);
    ctx.lineTo(parseInt(windowWidth / 2 - zoomFactor * offset, 10) + 0.5, 20);
    ctx.stroke();

    if ((offset * 2) % 100 === 0) {
      ctx.fillText(offset * 2, parseInt(windowWidth / 2 + zoomFactor * offset, 10) + 1.5, 14);
      if (offset !== 0) {
        ctx.fillText(offset * 2, parseInt(windowWidth / 2 - zoomFactor * offset, 10) + 1.5, 14);
      }
    }

    offset += 5;
  } while (offset <= (windowWidth / 2) * (1 / zoomFactor));
};

const SORTBY = {
  OCCURENCE: 'OCCURENCCE',
  SIZE: 'SIZE',
};

const sortFunction = {
  [SORTBY.OCCURENCE]: (item) => -item.occurence,
  [SORTBY.SIZE]: (item) => item.width,
};

class Index extends React.Component {
  constructor() {
    super();

    this.state = {
      viewName: '',
      properties: [],
      browserData: [],
      panes: 6,
      mostOccurence: 10000,
      total: 10000,
      sortBy: SORTBY.OCCURENCE,
      showOpen: true,
      horizontal: false,
    };

    this.canvas;
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    document.body.appendChild(script);

    const script2 = document.createElement('script');
    script2.src = 'https://apis.google.com/js/api.js';
    document.body.appendChild(script2);

    setTimeout(() => {
      if (window.__polypane) {
        this.setState({ showOpen: false });
      }
    }, 200);

    if (this.canvas) {
      drawOnCanvas(this.canvas);
    }

    window.addEventListener('resize', this.drawCanvas);
  }

  getProperties = async () => {
    const ref = this;

    await new Promise((resolve, reject) => {
      window.gapi.load('client', { callback: resolve, onerror: reject });
    });

    await window.gapi.client.init({}).then(async () => {
      window.gapi.client.load('https://analytics.googleapis.com/$discovery/rest?version=v3');
    });

    const client = window.google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: 'https://www.googleapis.com/auth/analytics.readonly',
      callback: async (response) => {
        window.gapi.client.setToken({ access_token: response.access_token });

        const ga3props = await window.gapi.client
          .request({
            path: 'https://www.googleapis.com/analytics/v3/management/accountSummaries',
            headers: { 'Content-Type': 'application/json' },
          })
          .then((result) => JSON.parse(result.body))
          .then((results) => results.items)
          .then((items) => {
            const properties = items
              .filter((item) => item.webProperties?.length)
              .map((item) => {
                const { name } = item;
                return item.webProperties.map((prop) => {
                  const propName = prop.name;
                  return prop.profiles.map((profile) => ({
                    type: 'ga3',
                    name,
                    propName,
                    profileName: profile.name,
                    id: profile.id,
                  }));
                });
              })
              .flat(Infinity);

            return properties;
          })
          .catch((e) => {
            console.log(e);
          });

        const properties = [...(ga3props || [])];

        ref.setState({ properties });
        ref.getBrowserSizes(properties[0]?.id);
      },
    });

    client.requestAccessToken();
  };

  componentDidUpdate() {
    this.drawCanvas();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.drawCanvas);
  }

  drawCanvas = () => {
    if (this.canvas) {
      drawOnCanvas(this.canvas);
    }
  };

  logout = (e) => {
    e.preventDefault();
    let cred = window.gapi.client.getToken();
    if (cred !== null) {
      window.google.accounts.oauth2.revoke(cred.access_token, () => {
        console.log('Revoked: ' + cred.access_token);
      });
      window.gapi.client.setToken('');
    }

    this.setState({ viewName: '', properties: [], browserData: [], panes: 6, total: 10000 });
  };

  setViewName = (id) => {
    const view = this.state.properties.filter((prop) => prop.id === id)[0];
    this.setState({ viewName: `${view.name} ${view.propName} ${view.profileName}` });
  };

  getBrowserSizes = (id) => {
    this.setState({ browserData: [], horizontalBrowserData: [] });

    window.gapi.client
      .request({
        path: '/v4/reports:batchGet',
        root: 'https://analyticsreporting.googleapis.com/',
        method: 'POST',
        body: {
          reportRequests: [
            {
              viewId: `ga:${id}`,
              dateRanges: [
                {
                  startDate: '2023-05-31',
                  endDate: '2023-06-30',
                },
              ],
              metrics: [
                {
                  expression: 'ga:sessions',
                },
              ],
              dimensions: [
                {
                  name: 'ga:browserSize',
                },
              ],
              orderBys: [
                {
                  fieldName: 'ga:sessions',
                  sortOrder: 'DESCENDING',
                },
              ],
            },
          ],
        },
      })
      .then((response) => {
        const total = response.result.reports[0].data.totals[0].values[0];

        const browserData = sortBy(
          response.result.reports[0].data.rows.map((item) => ({
            dimensions: item.dimensions[0],
            width: parseInt(item.dimensions[0].split('x')[0] || 0, 10),
            height: parseInt(item.dimensions[0].split('x')[1] || 0, 10),
            occurence: parseInt(item.metrics[0].values[0], 10),
          })),
          'occurence'
        )
          .filter((item) => item.dimensions !== '(not set)')
          .reverse();

        const horizontalBrowserData = sortBy(
          browserData.reduce((arr, current) => {
            const exists = arr.findIndex((x) => x.width === current.width);

            if (exists > -1) {
              const existing = arr[exists];
              arr[exists] = {
                ...existing,
                occurence: existing.occurence + current.occurence,
              };
            } else {
              arr.push({
                width: current.width,
                occurence: current.occurence,
              });
            }
            return arr;
          }, []),
          'occurence'
        ).reverse();

        this.setViewName(id);
        this.setState({
          total,
          mostOccurence: browserData[0].occurence,
          browserData,
          horizontalBrowserData,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  handleSlider = (e) => {
    const panes = parseInt(e.target.value, 10);
    this.setState({ panes });
  };

  handleSort = (e) => {
    const sortBy = this.state.sortBy === SORTBY.OCCURENCE ? SORTBY.SIZE : SORTBY.OCCURENCE;
    this.setState({ sortBy });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.getBrowserSizes(e.target.value);
  };

  setHorizontal = (e) => {
    this.setState({ horizontal: e.target.checked });
  };

  download() {
    const workspace = {
      name: this.state.viewName,
      workspace: (this.state.horizontal ? this.state.horizontalBrowserData : this.state.browserData)
        .slice(0, this.state.panes)
        .map((item) => ({
          width: item.width,
          height: item.height || 'auto',
          sizeType: 'px',
          zoom: '1',
          name: `${item.dimensions || item.width} (${parseFloat((item.occurence / this.state.total) * 100).toFixed(
            2
          )}%)`,
          sizes: {
            width: item.width,
            height: item.height || 800,
            sizeType: 'px',
            zoom: '1',
          },
        })),
    };

    const file = new Blob([JSON.stringify(workspace)], { type: 'application/json' });
    return URL.createObjectURL(file);
  }

  open() {
    return `polypane://polypane.app/exported-panes/?panesizes=${encodeURIComponent(
      JSON.stringify(
        (this.state.horizontal ? this.state.horizontalBrowserData : this.state.browserData)
          .slice(0, this.state.panes)
          .map((item) => ({
            width: item.width,
            height: item.height || 800,
            title: `${item.dimensions || item.width} (${parseFloat((item.occurence / this.state.total) * 100).toFixed(
              2
            )}%)`,
          }))
      )
    )}`;
  }

  render() {
    const data = this.state.horizontal ? this.state.horizontalBrowserData : this.state.browserData;

    return (
      <Layout>
        <SEO
          title="Create workspace"
          keywords={['']}
          description="Create a Polypane workspace with the most used browser sizes based on your Google Analytics v3 data from June 2023."
          pathname={this.props.location.pathname}
          ogFileName="create-workspace"
          ogCustomPath="/og-custom/"
        />

        <PageHeader>
          <svg
            className={styles.icon}
            width="137"
            height="60"
            viewBox="0 0 137 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient id="sqgradient" gradientTransform="rotate(45)">
                <stop offset="0" stopColor="rgb(44, 130, 173)" stopOpacity="1" />

                <stop offset="1" stopColor="rgb(103, 178, 111)" stopOpacity="1" />
              </linearGradient>
            </defs>
            <rect width="53" height="60" rx="8" fill="url(#sqgradient)" />
            <rect x="60" width="43" height="50" rx="8" fill="url(#sqgradient)" />
            <rect x="110" width="27" height="37" rx="8" fill="url(#sqgradient)" />
          </svg>

          <h1>Create Polypane Workspace</h1>
          <h2>
            Create a Polypane workspace with the most used browser sizes based on your{' '}
            <strong>Google Analytics v3</strong> data from{' '}
            <span style={{ textDecoration: 'line-through' }}>the past 30 days</span> June 2023.
          </h2>
          <p style={{ marginBottom: '1rem' }}>
            <span class={styles.badge}>GA3 is deprecated</span> Unfortunately, Google Analytics v4 doesn't track browser
            sizes without a custom tracking script. <Link to="/support/">Let us know</Link> if you want Polypane to
            provide a script, or if there are other analytics tools we should support.
          </p>
        </PageHeader>

        <div className={styles.form}>
          <div className={styles.button} id="my-signin2">
            {this.state.properties.length ? (
              <button className={styles.signout} type="button" onClick={this.logout}>
                <span>
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className="LgbsSe-Bz112c">
                    <g>
                      <path
                        fill="#EA4335"
                        d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                      />
                      <path
                        fill="#4285F4"
                        d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                      />
                      <path
                        fill="#FBBC05"
                        d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                      />
                      <path
                        fill="#34A853"
                        d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                      />
                      <path fill="none" d="M0 0h48v48H0z" />
                    </g>
                  </svg>
                </span>
                Sign out with Google
              </button>
            ) : (
              <button className={styles.signin} type="button" onClick={this.getProperties}>
                <span>
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className="LgbsSe-Bz112c">
                    <g>
                      <path
                        fill="#EA4335"
                        d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                      />
                      <path
                        fill="#4285F4"
                        d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                      />
                      <path
                        fill="#FBBC05"
                        d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                      />
                      <path
                        fill="#34A853"
                        d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                      />
                      <path fill="none" d="M0 0h48v48H0z" />
                    </g>
                  </svg>
                </span>
                Sign In With Google
              </button>
            )}
          </div>
          <p>
            <em>This web app runs entirely in your browser and no data is shared with Polypane.</em>
          </p>
        </div>
        {this.state.properties.length ? (
          <>
            <div className={styles.form}>
              <div className={styles.selectwrap}>
                <label htmlFor="viewid">
                  Select a Google Analytics v3 profile <em>(account &gt; property &gt; view)</em>
                </label>
                <small>Google Analytics v4 doesn't collect browser sizes, so is excluded from the list below.</small>
                <select name="viewid" id="viewid" onChange={this.handleSubmit}>
                  {this.state.properties.map((p) =>
                    p.type === 'ga3' ? (
                      <option key={p.id} value={p.id}>
                        {p.name} 🢒 {p.propName} 🢒 {p.profileName}
                      </option>
                    ) : (
                      <option key={p.id} value={p.id}>
                        {p.name} 🢒 {p.profileName}
                      </option>
                    )
                  )}
                </select>
              </div>
            </div>

            <div className={styles.paneBlock}>
              {data.length ? (
                <>
                  <div className={styles.actionBar}>
                    <fieldset className={styles.sliderFieldset}>
                      <input
                        id="numberofpanes"
                        name="numberofpanes"
                        type="range"
                        min="1"
                        max="15"
                        step="1"
                        value={this.state.panes}
                        onChange={this.handleSlider}
                      />
                      <label htmlFor="numberofpanes">{this.state.panes} panes</label>
                    </fieldset>

                    <fieldset className={styles.sortFieldset}>
                      <div>
                        <legend>Sort by:</legend>
                        <label>
                          <input
                            onChange={this.handleSort}
                            name="sortBy"
                            checked={this.state.sortBy === SORTBY.OCCURENCE}
                            value={SORTBY.OCCURENCE}
                            type="radio"
                          />{' '}
                          occurrence
                        </label>
                        <label>
                          <input
                            onChange={this.handleSort}
                            name="sortBy"
                            checked={this.state.sortBy === SORTBY.SIZE}
                            value={SORTBY.SIZE}
                            type="radio"
                          />{' '}
                          size
                        </label>
                      </div>
                    </fieldset>

                    <fieldset className={styles.horizontalFieldset}>
                      <label>
                        <input
                          onChange={this.setHorizontal}
                          name="setHorizontal"
                          checked={this.state.horizontal}
                          type="checkbox"
                        />{' '}
                        Only horizontal breakpoints
                      </label>
                    </fieldset>
                  </div>
                  <ul className={styles.panelist}>
                    <AnimatePresence>
                      {sortBy(data.slice(0, this.state.panes), sortFunction[this.state.sortBy]).map((item) => (
                        <motion.li
                          key={item.width + item.occurence}
                          positionTransition
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                        >
                          <div
                            style={{
                              width: item.width / 8,
                              height: item.height / 8 || 100,
                            }}
                          >
                            {item.dimensions || item.width}
                            <i>{parseFloat((item.occurence / this.state.total) * 100).toFixed(2)}%</i>
                          </div>
                        </motion.li>
                      ))}
                    </AnimatePresence>
                  </ul>
                  <div className={styles.actionBar}>
                    {this.state.showOpen && (
                      <a className={styles.open} href={this.open()}>
                        Open in Polypane <Upload width={24} height={24} />
                      </a>
                    )}
                    <a
                      className={styles.download}
                      href={this.download()}
                      download={`polypane workpace ${this.state.viewName}.ppws`}
                    >
                      Download <i>Workspace file</i> <Download width={24} height={24} />
                    </a>
                  </div>
                </>
              ) : (
                <p className={styles.loading}>Loading browser dimensions info&hellip;</p>
              )}
            </div>
            {this.state.browserData.length ? (
              <>
                <div className={styles.canvasTitle}>
                  <h3>Your visitors browser sizes visualized</h3>
                  <p>
                    Darker lines mean more visitors used this size. Sizes used by less than {cutOffPercentage}% of
                    visitors are filtered out. The values on the ruler are for the entire width, so if you see a line at
                    400px, then that pane is 400px wide.
                  </p>
                  <ul className={styles.legend}>
                    {this.state.browserData
                      .reduce(
                        (array, item) => {
                          const width = parseInt(item.dimensions.split('x')[0], 10);
                          const index =
                            width < CATEGORIES.xsmall
                              ? 0
                              : width < CATEGORIES.small
                              ? 1
                              : width < CATEGORIES.medium
                              ? 2
                              : width < CATEGORIES.large
                              ? 3
                              : 4;

                          array[index] += item.occurence;
                          return array;
                        },
                        [0, 0, 0, 0, 0]
                      )
                      .map((item, index) => (
                        <li key={index}>
                          <div className={[styles.color, getCategoryClass(index)].join(' ')} />
                          {getCategoryName(index)} ({parseFloat((item / this.state.total) * 100).toFixed(2)}%)
                        </li>
                      ))}
                  </ul>
                </div>

                <canvas
                  ref={(c) => {
                    this.canvas = c;
                  }}
                />
                <div
                  className={styles.sizeOverview}
                  style={{
                    height: `${parseInt(
                      sortBy(
                        this.state.browserData.filter(
                          (item) => item.occurence / this.state.mostOccurence > cutOffPercentage
                        ),
                        (item) => -parseInt(item.dimensions.split('x')[1], 10)
                      )[0].dimensions.split('x')[1] * zoomFactor,
                      10
                    )}px`,
                  }}
                >
                  {sortBy(
                    [
                      ...this.state.browserData,
                      {
                        dimensions: `${window.innerWidth}x${window.innerHeight}`,
                        occurence: this.state.mostOccurence,
                        thisScreen: true,
                      },
                    ],
                    sortFunction[SORTBY.SIZE]
                  )
                    .filter((item) => item.occurence / this.state.mostOccurence > cutOffPercentage)
                    .reverse()
                    .map((item) => {
                      const width = parseInt(item.dimensions.split('x')[0], 10);
                      const height = parseInt(item.dimensions.split('x')[1], 10);
                      return (
                        <div
                          key={item.dimensions}
                          className={[
                            styles.dimensions,
                            item.thisScreen ? styles.mine : '',
                            width < CATEGORIES.xsmall
                              ? styles.xsmall
                              : width < CATEGORIES.small
                              ? styles.small
                              : width < CATEGORIES.medium
                              ? styles.medium
                              : width < CATEGORIES.large
                              ? styles.large
                              : styles.xlarge,
                          ].join(' ')}
                          style={{
                            width: `${width}px`,
                            height: `${height}px`,
                            opacity: item.occurence / this.state.mostOccurence,
                          }}
                        >
                          {item.thisScreen ? (
                            <>
                              Your browser size
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                          {width < CATEGORIES.xsmall
                            ? 'Extra small'
                            : width < CATEGORIES.small
                            ? 'Small'
                            : width < CATEGORIES.medium
                            ? 'Medium'
                            : width < CATEGORIES.large
                            ? 'Large'
                            : 'Extra large'}
                          <br />
                          {item.dimensions}
                          <br />
                          {item.thisScreen ? (
                            ''
                          ) : (
                            <i>{parseFloat((item.occurence / this.state.total) * 100).toFixed(2)}%</i>
                          )}
                        </div>
                      );
                    })}
                </div>
              </>
            ) : null}
          </>
        ) : null}
        <div className={styles.intro}>
          <h2>
            The best sizes to test on <br />
            <strong>are the sizes your visitors use the most.</strong>
          </h2>
          <p>
            Create a Polypane workspace from your website's most popular browser sizes for June 2023, based on Google
            Analytics v3.
          </p>
          <ol>
            <li>Sign in with Google</li>
            <li>Select your Google Analytics v3 property</li>
            <li>Choose how many panes you want in your workspace</li>
            <li>
              <strong>Open it directly into Polypane</strong> or download your workspace and share it with your team!
            </li>
          </ol>
        </div>
        <CTA />
      </Layout>
    );
  }
}

export default Index;
